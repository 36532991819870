var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import 'sanitize.css';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styled, { createGlobalStyle } from 'styled-components';
import chunkp from 'chunk-pattern';
import { theme } from './theme';
import logo from 'url:./assets/logo.svg';
var formatters = {
    phone_number: function (value) {
        var string = value.replace('63', '0').replace('+', '').replace(/\s/g, '');
        var chunked = chunkp(string.split(''), [4, 3, 4]);
        return chunked.map(function (group) { return group.join(''); });
    },
    group_by_threes: function (value) {
        var string = value.replace(/\s/g, '');
        var chunked = chunkp(string.split(''), [3]);
        return chunked.map(function (group) { return group.join(''); });
    },
    group_by_fours: function (value) {
        var string = value.replace(/\s/g, '');
        var chunked = chunkp(string.split(''), [4]);
        return chunked.map(function (group) { return group.join(''); });
    }
};
var defaultContent = {
    phone_number: ['0000', '000', '0000'],
    group_by_threes: ['000', '000', '000', '000'],
    group_by_fours: ['0000', '0000', '0000']
};
var App = function () {
    var _a = __read(useState(''), 2), input = _a[0], setInput = _a[1];
    var _b = __read(useState('phone_number'), 2), format = _b[0], setFormat = _b[1];
    var content = input ? formatters[format](input) : defaultContent[format];
    return (React.createElement(React.Fragment, null,
        React.createElement(GlobalStyle, null),
        React.createElement(Layout, null,
            React.createElement(LayoutSection, null,
                React.createElement(Container, null,
                    React.createElement("img", { src: logo }))),
            React.createElement(MainContent, null,
                React.createElement(Container, null,
                    React.createElement(Content, null,
                        React.createElement(Controls, null,
                            React.createElement(ControlsField, null,
                                React.createElement(Input, { type: "text", placeholder: "Enter number...", value: input, onChange: function (evt) { return setInput(evt.target.value); }, autoFocus: true })),
                            React.createElement(ControlsField, null,
                                React.createElement(Select, { value: format, onChange: function (evt) { return setFormat(evt.target.value); } },
                                    React.createElement("option", { value: "phone_number" }, "Phone Number"),
                                    React.createElement("option", { value: "group_by_threes" }, "Group By Threes"),
                                    React.createElement("option", { value: "group_by_fours" }, "Group By Fours")))),
                        React.createElement(SuperNumber, null, content.map(function (group, i) { return (React.createElement(Colorify, { index: i, key: i },
                            group,
                            " \u00A0")); }))))),
            React.createElement(LayoutSection, null,
                React.createElement(Container, null,
                    React.createElement(Footer, null,
                        React.createElement(FooterLink, { href: "https://github.com/srph/chunkr" }, "GitHub"),
                        React.createElement(FooterLink, { href: "https://kierb.com", target: "_blank" }, "Kier Borromeo")))))));
};
var GlobalStyle = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  * {\n    box-sizing: border-box\n  }\n\n  html, body {\n    font-family: ", ";\n    font-size: ", "px;\n    color: ", ";\n    background: ", ";\n  }\n"], ["\n  * {\n    box-sizing: border-box\n  }\n\n  html, body {\n    font-family: ", ";\n    font-size: ", "px;\n    color: ", ";\n    background: ", ";\n  }\n"])), theme.fontFamily.sans, theme.fontSizes.base, theme.colors.white, theme.colors.neutral900);
var Layout = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  min-height: 720px;\n  height: 100vh;\n"], ["\n  display: flex;\n  flex-direction: column;\n  min-height: 720px;\n  height: 100vh;\n"])));
var LayoutSection = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex-shrink: 0;\n"], ["\n  flex-shrink: 0;\n"])));
var Footer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"])));
var FooterLink = styled.a(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-transform: uppercase;\n  font-size: ", "px;\n  font-weight: 700;\n  color: ", ";\n  opacity: 1;\n  text-decoration: none;\n"], ["\n  text-transform: uppercase;\n  font-size: ", "px;\n  font-weight: 700;\n  color: ", ";\n  opacity: 1;\n  text-decoration: none;\n"])), theme.fontSizes.sm, theme.colors.neutral300);
var Container = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 32px;\n"], ["\n  padding: 32px;\n"])));
var MainContent = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: 100%;\n"])));
var Content = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  max-width: 1400px;\n  margin: 0 auto;\n"], ["\n  max-width: 1400px;\n  margin: 0 auto;\n"])));
var Controls = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin-bottom: 48px;\n"], ["\n  display: flex;\n  align-items: center;\n  margin-bottom: 48px;\n"])));
var ControlsField = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 320px;\n\n  &:not(:last-child) {\n    margin-right: 16px;\n  }\n"], ["\n  width: 320px;\n\n  &:not(:last-child) {\n    margin-right: 16px;\n  }\n"])));
var Input = styled.input(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: block;\n  width: 100%;\n  padding: 16px;\n  background: transparent;\n  color: ", ";\n  font-size: ", "px;\n  font-weight: 700;\n  font-family: ", ";\n  text-transform: uppercase;\n  border: 1px solid ", ";\n  outline: 0;\n\n  &::placeholder {\n    color: ", ";\n  }\n\n  &:focus {\n    background: ", ";\n  }\n"], ["\n  display: block;\n  width: 100%;\n  padding: 16px;\n  background: transparent;\n  color: ", ";\n  font-size: ", "px;\n  font-weight: 700;\n  font-family: ", ";\n  text-transform: uppercase;\n  border: 1px solid ", ";\n  outline: 0;\n\n  &::placeholder {\n    color: ", ";\n  }\n\n  &:focus {\n    background: ", ";\n  }\n"])), theme.colors.white, theme.fontSizes.base, theme.fontFamily.sans, theme.colors.neutral800, theme.colors.neutral300, theme.colors.neutral800);
var Select = styled.select(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: block;\n  width: 100%;\n  padding: 16px;\n  background: transparent;\n  color: ", ";\n  font-size: ", "px;\n  font-weight: 700;\n  font-family: ", ";\n  text-transform: uppercase;\n  border: 1px solid ", ";\n  outline: 0;\n\n  &:focus {\n    background: ", ";\n  }\n"], ["\n  display: block;\n  width: 100%;\n  padding: 16px;\n  background: transparent;\n  color: ", ";\n  font-size: ", "px;\n  font-weight: 700;\n  font-family: ", ";\n  text-transform: uppercase;\n  border: 1px solid ", ";\n  outline: 0;\n\n  &:focus {\n    background: ", ";\n  }\n"])), theme.colors.white, theme.fontSizes.base, theme.fontFamily.sans, theme.colors.neutral800, theme.colors.neutral700);
var SuperNumber = styled.h1(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  margin: 0;\n  font-size: 144px;\n  font-weight: bold;\n  width: 100%;\n  color: ", ";\n"], ["\n  margin: 0;\n  font-size: 144px;\n  font-weight: bold;\n  width: 100%;\n  color: ", ";\n"])), theme.colors.purple);
var Colorify = styled.span(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (props) { return (props.index % 2 === 0 ? theme.colors.purple : theme.colors.teal); });
ReactDOM.render(React.createElement(App, null), document.getElementById('root'));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
