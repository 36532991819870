export var theme = {
    colors: {
        white: '#fff',
        neutral900: '#111827',
        neutral800: '#1F2937',
        neutral700: '#33445A',
        neutral300: '#bcd0e8',
        purple: '#cf4cfe',
        teal: '#00b9b9'
    },
    fontSizes: {
        sm: 12,
        base: 16
    },
    fontFamily: {
        sans: '"Work Sans", sans-serif'
    }
};
